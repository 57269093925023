import React, { useState, useEffect } from "react";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography,
  Avatar,
} from "@mui/material";
import { LgMenuList } from "./LgMenuList";
import { SmMenuList } from "./SmMenuList";
import {
  StyledAppBar,
  StyledToolbar,
  LogoContainer,
  LinksContainer,
  RightContainer,
} from "./styles";
import { SignUpDialog } from "../../auth/SignUp.js";
import CloseIcon from "@mui/icons-material/Close";
import HeadphonesIcon from "@mui/icons-material/Headset";
import { LoginDialog } from "../../auth/Login.js";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward"; // Import arrow icon
import { useNavigate } from "react-router-dom";

export function LandingHeader({ mode }) {
  const [open, setOpen] = useState(false);
  const [openLogin, setOpenLogin] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const userId = localStorage.getItem("userId");
    const authEmail = localStorage.getItem("authEmail");
    if (userId && userId !== "undefined" && authEmail) {
      setIsLoggedIn(true);
    }
  }, []);

  // Open and close dialog functions
  const handleClickOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const handleLoginOpen = () => setOpenLogin(true);
  const handleLoginClose = () => setOpenLogin(false);

  const scrollToSection = (sectionId) => {
    const sectionElement = document.getElementById(sectionId);
    const offset = 128;
    if (sectionElement) {
      const targetScroll = sectionElement.offsetTop - offset;
      sectionElement.scrollIntoView({ behavior: "smooth" });
      window.scrollTo({
        top: targetScroll,
        behavior: "smooth",
      });
      setOpen(false);
    }
  };

  const props = {
    open,
    setOpen,
    onClick: scrollToSection,
  };

  return (
    <StyledAppBar>
      <StyledToolbar
        sx={{
          justifyContent: "flex-start",
          alignItems: "center",
          height: "94px",
        }}
      >
        <LogoContainer>
          <Avatar
            alt="logo"
            src="./logo.png"
            sx={{ width: "50px", height: "50px" }}
          />
        </LogoContainer>
        <LinksContainer sx={{ display: "flex" }}>
          <LgMenuList {...props} />
          <SmMenuList {...props} />
        </LinksContainer>
        <RightContainer>
          <Typography
            variant="h6"
            sx={{
              cursor: "pointer",
              marginRight: 2,
              color: "#fff",
              fontSize: "16px",
              display: "flex",
              alignItems: "center",
            }}
            onClick={() => window.open("https://calendly.com/talha-b2bconnect/b2bconnect-ai", "_blank")}
          >
            <HeadphonesIcon sx={{ marginRight: 0.5 }} /> Talk To Sales
          </Typography>

          {/* Conditionally render buttons based on login state */}
          {isLoggedIn ? (
            <Button
              variant="contained"
              color="primary"
              sx={{
                borderRadius: "40px", // Make the button round
                paddingLeft: "12px",
                paddingRight: "12px",
                display: "flex",
                alignItems: "center",
              }}
              onClick={() => navigate("/dashboard")}
            >
              Dashboard <ArrowForwardIcon sx={{ marginLeft: 1 }} />{" "}
              {/* Small arrow at the end */}
            </Button>
          ) : (
            <>
              <Typography
                variant="h6"
                sx={{
                  cursor: "pointer",
                  marginRight: 2,
                  color: "#fff",
                  fontSize: "16px",
                  display: "flex",
                  alignItems: "center",
                }}
                onClick={handleLoginOpen}
              >
                LogIn
              </Typography>
              <Button
                variant="contained"
                color="success"
                onClick={handleClickOpen}
              >
                Sign Up
              </Button>
            </>
          )}
        </RightContainer>
      </StyledToolbar>

      {/* Signup Dialog */}
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle>
          Sign Up
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <SignUpDialog onClose={handleClose} />
        </DialogContent>
      </Dialog>

      {/* Login Dialog */}
      <Dialog
        open={openLogin}
        onClose={handleLoginClose}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>
          Login
          <IconButton
            aria-label="close"
            onClick={handleLoginClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <LoginDialog onClose={handleLoginClose} />
        </DialogContent>
      </Dialog>
    </StyledAppBar>
  );
}

export default LandingHeader;
