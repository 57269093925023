import React, { useState, useEffect } from "react";
import {
  Typography,
  Box,
  Dialog,
  IconButton,
  DialogContent,
} from "@mui/material";
import { HeroButton, HeroContainer, CheckBlock } from "./styles";
import { CheckCircle as CheckCircleIcon } from "@mui/icons-material";
import { SignUpDialog } from "../auth/SignUp.js";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";

export function HeroSection() {
  const [open, setOpen] = useState(false);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const userId = localStorage.getItem("userId");
    const authEmail = localStorage.getItem("authEmail");
    if (userId && userId !== "undefined" && authEmail) {
      setIsLoggedIn(true);
    }
  }, []);

  // Open the dialog
  const handleClickOpen = () => {
    if (isLoggedIn) {
      navigate("/dashboard");
    }
    else {
      setOpen(true);
    }
  };

  // Close the dialog
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <HeroContainer maxWidth="lg">
      <Typography
        variant="h2"
        component="h1"
        gutterBottom
        fontFamily="Inter, Sans-serif"
        fontWeight="bold"
      >
        AI <span style={{ color: "purple" }}>GTM</span> Agents Built for{" "}
        <span style={{ color: "blue" }}>Growth</span>
      </Typography>
      <Typography
        variant="subtitle1"
        component="p"
        gutterBottom
        fontFamily="Inter, Sans-serif"
        sx={{ fontSize: "18px", lineHeight: "1.5" }}
      >
        Find Business Emails, Enrich LinkedIn Data, and Search Ideal Customer
        Profiles.
      </Typography>
      <Typography
        variant="subtitle1"
        component="p"
        gutterBottom
        fontFamily="Inter, Sans-serif"
        sx={{ fontSize: "18px", lineHeight: "1.5" }}
      >
        All through our Go-To-Market AI Agents.
      </Typography>
      <HeroButton
        fontFamily="Inter, Sans-serif"
        onClick={handleClickOpen} // Use this to open the dialog
        variant="contained"
        color="secondary"
        sx={{  backgroundColor: "purple", color: "#fff" }}
      >
        Start For Free
      </HeroButton>
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <SignUpDialog onClose={handleClose} />
        </DialogContent>
      </Dialog>
      <Box sx={{ paddingTop: "24px", paddingBottom: "12px" }}>
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            justifyContent: "center",
            gridGap: "15px",
            marginTop: "12px",
          }}
        >
          <CheckBlock>
            <CheckCircleIcon
              sx={{ color: "green", marginRight: "8px", marginTop: "3px" }}
            />
            <Typography
              variant="subtitle1"
              component="p"
              fontFamily="Inter, Sans-serif"
              sx={{ fontSize: "18px", color: "#ffffff", textAlign: "left" }} // Adjusted color to be visible
            >
              No Credit Card Required
            </Typography>
          </CheckBlock>
          <CheckBlock>
            <CheckCircleIcon
              sx={{ color: "green", marginRight: "8px", marginTop: "3px" }}
            />
            <Typography
              variant="subtitle1"
              component="p"
              fontFamily="Inter, Sans-serif"
              sx={{ fontSize: "18px", color: "#ffffff", textAlign: "left" }} // Adjusted color to be visible
            >
              Try Out with Free Credits
            </Typography>
          </CheckBlock>
        </Box>
      </Box>
    </HeroContainer>
  );
}

export default HeroSection;
