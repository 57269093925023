import React, { useState } from "react";
import axios from "axios"; // Make sure to install axios if not already
import { Dialog } from "@mui/material";
import { SignUpForm } from "./SignUpFormBody";
import { BaseURL, saveAuthData } from "../common/constants";
import { LoginForm } from "./LoginFormBody"; // Import LoginForm

export function SignUpDialog({ onClose, ...rest }) {
  const [formValues, setFormValues] = useState({
    name: "",
    email: "",
    phone: "",
    password: "",
    company: "",
    icp: "",
  });
  
  const [loginFormValues, setLoginFormValues] = useState({  // Add separate state for login
    email: "",
    password: ""
  });

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [isLoginForm, setIsLoginForm] = useState(false);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    if (isLoginForm) {
      setLoginFormValues(prev => ({
        ...prev,
        [name]: value
      }));
    } else {
      setFormValues(prev => ({
        ...prev,
        [name]: value
      }));
    }
  };

  const validate = () => {
    let tempErrors = {};
    tempErrors.name = formValues.name ? "" : "This field is required.";
    tempErrors.email = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/.test(formValues.email)
      ? ""
      : "Email is not valid.";
    tempErrors.phone = formValues.phone ? "" : "Phone number is not valid.";
    tempErrors.company = formValues.company ? "" : "This field is required.";
    tempErrors.icp = formValues.icp ? "" : "This field is required.";
    setErrors(tempErrors);

    return Object.values(tempErrors).every((x) => x === "");
  };

  const handleSubmit = async (event) => {  // Added this function
    event.preventDefault();
    if (!loading && validate()) {
      try {
        setLoading(true);
        const response = await axios.post(`${BaseURL}/auth/register`, formValues);
        saveAuthData(response);
        onClose();
        setTimeout(() => {
          window.location.reload();
        }, 200);
      } catch (error) {
        setErrors((prev) => ({
          ...prev,
          email: error?.response?.data?.message,
        }));
      } finally {
        setLoading(false);
      }
    }
  };

  const handleLoginSubmit = async (event) => {
    event.preventDefault();
    if (!loading) {
      try {
        setLoading(true);
        const response = await axios.post(`${BaseURL}/auth/login`, loginFormValues);
        if (response.status === 204) {
          // Handle password creation if needed
        } else {
          saveAuthData(response);
          onClose();
          setTimeout(() => {
            window.location.reload();
          }, 200);
        }
      } catch (error) {
        setErrors((prev) => ({
          ...prev,
          auth: error?.response?.data?.message || "An error occurred during login"
        }));
      } finally {
        setLoading(false);
      }
    }
  };

  const handleToggle = () => {
    setIsLoginForm(prev => !prev);
    setErrors({}); // Clear errors when switching forms
  };

  const loginProps = {
    formValues: loginFormValues,
    handleInputChange,
    handleSubmit: handleLoginSubmit,
    loading,
    errors,
    onRegister: handleToggle,
  };

  const signUpProps = {
    formValues,
    handleInputChange,
    handleSubmit,
    loading,
    errors,
    onLogin: handleToggle,
  };

  return (
    <React.Fragment>
      <Dialog
        open
        onClose={onClose}
        aria-labelledby="form-dialog-title"
        disableEscapeKeyDown
        sx={{ backdropFilter: "blur(3px)" }} // Translucent backdrop
      >
        {isLoginForm ? (
          <LoginForm {...loginProps} />
        ) : (
          <SignUpForm {...signUpProps} {...rest} />
        )}
      </Dialog>
    </React.Fragment>
  );
}
