import React from "react";
import { Button } from "@mui/material";
import { Routes, Route, Link } from "react-router-dom";
import { styled } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import AppBar from "@mui/material/AppBar";
import { Avatar } from "@mui/material";
import { EventsPage } from "./eventsList";
import { ListSingleEvent } from "./singleEvent";
import { Profile } from "./profile";
import { SettingsPage } from "./settings";
import { EnrichmentOptions, Enrichment, Workspace } from "./enrichment";
import { EnrichTypes } from "./enrichment/components/constants";

export const StyledLink = styled(Link)(({ theme }) => ({
  textDecoration: "none",
  color: "inherit !important",
  "&:hover": {
    color: theme.palette.primary.main,
  },
}));

export function Layout() {
  return (
    <>
      <AppBar position="static">
        <Toolbar sx={{ justifyContent: "space-between" }}>
          <div>
            <StyledLink to="/dashboard">
              <Avatar alt="logo" src="./logo.png" />
            </StyledLink>
            {/* <Button color="secondary" component={StyledLink} to="/">
              Home
            </Button>
            <Button color="inherit" component={StyledLink} to="/events">
              Explore Events
            </Button>
            <Button color="inherit" component={StyledLink} to="/enrichment">
              AI Data Enrich
            </Button> */}
          </div>
          <Profile />
        </Toolbar>
      </AppBar>
      <Routes>
        <Route path="/event/:eventId" element={<ListSingleEvent />} />
        <Route path="/events" element={<EventsPage />} />
        <Route path="/settings" element={<SettingsPage />} />
        <Route path="/dashboard" element={<Workspace />} />
        <Route path="/dashboard/email" element={<EnrichmentOptions />} />
        <Route
          path="/dashboard/linkedin"
          element={<Enrichment type={EnrichTypes.Linkedin} />}
        />
        <Route
          path="/dashboard/email/csv-upload"
          element={<Enrichment type={EnrichTypes.Email} />}
        />
      </Routes>
    </>
  );
}
