import React, { useState } from "react";
import { Dialog } from "@mui/material";
import axios from "axios";
import { LoginForm } from "./LoginFormBody"; // Adjust the import path if necessary
import { SignUpDialog } from "./SignUp"; // Import SignUpDialog
import { BaseURL, saveAuthData } from "../common/constants";

export function LoginDialog({ onClose }) {
  const [formValues, setFormValues] = useState({
    email: "",
    password: "",
  });

  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [showSignUp, setShowSignUp] = useState(false); // Add state for signup dialog

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormValues(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!loading) {
      try {
        setLoading(true);
        const response = await axios.post(`${BaseURL}/auth/login`, formValues);
        if (response.status === 204) {
          // Handle password creation if needed
        } else {
          saveAuthData(response);
          onClose();
          setTimeout(() => {
            window.location.reload();
          }, 200);
        }
      } catch (error) {
        setErrors((prev) => ({
          ...prev,
          auth: error?.response?.data?.message || "An error occurred during login"
        }));
      } finally {
        setLoading(false);
      }
    }
  };

  const handleRegister = () => {
    onClose(); // Close the login dialog
    setShowSignUp(true); // Show the signup dialog
  };

  if (showSignUp) {
    return <SignUpDialog onClose={() => setShowSignUp(false)} />;
  }

  return (
    <Dialog
      open={true}
      onClose={onClose}
      aria-labelledby="form-dialog-title"
      disableEscapeKeyDown
      sx={{ backdropFilter: "blur(3px)" }}
    >
      <LoginForm
        formValues={formValues}
        handleInputChange={handleInputChange}
        handleSubmit={handleSubmit}
        loading={loading}
        errors={errors}
        onRegister={handleRegister}
      />
    </Dialog>
  );
}
